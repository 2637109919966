import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import { ComponentStyleProps } from 'src/types'

type Props = {
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
} & ComponentStyleProps

type PageHeadingProps = {
  heading: string
  subHeading: string
} & Props

const ScPageHeadingWrapper = styled.div`
  line-height: 1;
`

const ScPageHeading = styled.h1`
  line-height: 1.2;
  font-family: ${({ theme }) => theme.fontFamily.headingEn};
  font-size: 2.5rem;
  font-weight: bold;
  word-break: break-word;
  ${mediaQuery('sm')} {
    font-size: 4rem;
  }
`

const ScPageSubHeading = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.5em;
  position: relative;
  &::before {
    background-color: #000;
    content: '';
    display: block;
    height: 1px;
    margin-right: 0.5rem;
    width: 40px;
  }
  ${mediaQuery('sm')} {
    font-size: 1.5rem;
    &::before {
      margin-right: 1rem;
      width: 72px;
    }
  }
`

export const PageHeading: React.FC<PageHeadingProps> = (props) => {
  const { heading, subHeading, component = 'h1' } = props
  const Heading = ScPageHeading.withComponent(component)
  return (
    <ScPageHeadingWrapper {...props}>
      <Heading>{subHeading.toLocaleUpperCase()}</Heading>
      <ScPageSubHeading>{heading}</ScPageSubHeading>
    </ScPageHeadingWrapper>
  )
}

const ScHeading = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  ${mediaQuery('sm')} {
    font-size: 2rem;
  }
`

export const Heading: React.FC<Props> = (props) => {
  const { component = 'h3', children } = props
  // eslint-disable-next-line no-shadow
  const Heading = ScHeading.withComponent(component)
  return <Heading {...props}>{children}</Heading>
}
