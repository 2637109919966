import { ServiceListQueryExQuery } from 'graphql/graphql-types'

/**
 * kebab case to Pascal case
 * @param str string
 */
export const kebab2Pascal = (str: string): string => {
  const char = str.replace(/-./g, (s) => s.charAt(1).toUpperCase())
  return char.replace(/^./, (c) => c.charAt(0).toUpperCase())
}

type Edges = ServiceListQueryExQuery['allMarkdownRemark']['edges']

export const orderServices = (edges: Edges): void => {
  const order = ['web-marketing', 'system-integration', 'it-product', 'consulting']
  edges.sort((x: any, y: any) => {
    return order.indexOf(x.node.frontmatter.slug) - order.indexOf(y.node.frontmatter.slug)
  })
}
