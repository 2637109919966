import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import { Link } from 'gatsby'
import { ComponentStyleProps } from 'src/types'
import { getIcon } from 'src/images/icons'

type Props = {
  type?: 'button' | 'submit' | 'reset'
  icon?: 'checked' | 'chevron'
} & ComponentStyleProps

// Button
export const Button: React.FC<Props> = (props) => {
  const { type, icon, children } = props
  return (
    <ScButton type={type} {...props}>
      <ScInner>
        {children}
        {!!icon && <ScIcon src={getIcon(icon)} />}
      </ScInner>
    </ScButton>
  )
}

const ScButton = styled.button`
  background-color: ${({ theme }) => theme.color.button.primary};
  border: none;
  border-radius: 80px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  padding: 1em 2em;
  text-align: center;
  transition: 0.2s;
  &:hover {
    box-shadow: 0 16px 24px -16px rgba(0, 0, 0, 0.3);
    color: #fff;
  }
  ${mediaQuery('sm')} {
    font-size: 1.125rem;
  }
`

// LinkButton
type LinkButtonProps = {
  to: string
  icon?: 'checked' | 'chevron'
} & ComponentStyleProps

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { to, icon, children } = props
  return (
    <ScLinkButton to={to}>
      <ScInner>
        {children}
        {!!icon && <ScIcon src={getIcon(icon)} />}
      </ScInner>
    </ScLinkButton>
  )
}

const ScLinkButton = styled(Link)`
  background-color: ${({ theme }) => theme.color.button.primary};
  border-radius: 80px;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  padding: 1em 2em;
  text-align: center;
  transition: 0.2s;
  &:hover {
    box-shadow: 0 16px 24px -16px rgba(0, 0, 0, 0.3);
    color: #fff;
  }
  ${mediaQuery('sm')} {
    font-size: 1.125rem;
  }
`

const ScInner = styled.div`
  display: flex;
  align-items: center;
`

const ScIcon = styled.img`
  display: block;
  height: 20px;
  margin-left: 0.5rem;
  width: 20px;
  ${mediaQuery('sm')} {
    height: 24px;
    margin-left: 1rem;
    width: 24px;
  }
`

// ButtonWrapper
type ButtonWrapperProps = {
  align?: 'left' | 'center' | 'right'
} & ComponentStyleProps

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  ${({ align }) =>
    !!align &&
    `
    text-align: ${align};
  `}
`
