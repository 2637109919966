/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, FormEvent } from 'react'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import Layout from 'src/components/Layout'
import { PageHeading } from 'src/components/molecules/Heading'
import { Inner } from 'src/components/layouts/Inner'
import styled, { mediaQuery } from 'src/styles'
import { Button } from 'src/components/domains/app/Button'
import { useSiteMetadata } from 'src/hooks'
import PageMeta from 'src/components/PageMeta'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

const contactType = (ref: string | string[]) => {
  switch (ref) {
    case 'web-marketing':
      return 'WEBマーケティングについて'
    case 'system-integration':
      return 'システムインテグレーションについて'
    case 'it-product':
      return 'ITプロダクトについて'
    case 'consulting':
      return 'コンサルティングについて'
    case 'other':
      return 'その他'
    default:
      return ref
  }
}

type Props = {
  location: Location
}

const ContactPage: React.FC<Props> = ({ location }) => {
  const { siteTitle } = useSiteMetadata()
  const parsed = queryString.parse(location.search)
  // const [isValidated, setIsValidated] = useState(false)
  const [formData, SetFormData] = useState({
    type: parsed.ref ? parsed.ref : '',
    name: '',
    company: '',
    email: '',
    message: '',
  })

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      e.persist()
      SetFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }))
    },
    []
  )

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const data = { ...formData }
    data.type = contactType(data.type)
    e.preventDefault()
    // const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...data,
      }),
    })
      // .then(() => navigate(form.getAttribute('action')))
      .then(() => navigate('/contact/complete/'))
      // eslint-disable-next-line no-alert
      .catch((error) => alert(error))
  }

  return (
    <Layout>
      <PageMeta title={`お問い合わせ | ${siteTitle}`} />
      <Inner>
        <ScPageHeading heading="お問い合わせ" subHeading="Contact" />

        <ScSection>
          <form
            name="contact"
            method="post"
            action="/contact/complete/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={(e) => handleSubmit(e)}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label htmlFor="bot-field">
                <input id="bot-field" name="bot-field" onChange={(e) => handleChange(e)} />
              </label>
            </div>
            <div className="field">
              <label htmlFor="select" className="label">
                問い合わせ種別*
              </label>
              <div className="control">
                <div className="select">
                  <select name="type" id="select" value={formData.type} required onChange={(e) => handleChange(e)}>
                    <option value="">選択してください</option>
                    <option value="web-marketing">WEBマーケティングについて</option>
                    <option value="system-integration">システムインテグレーションについて</option>
                    <option value="it-product">ITプロダクトについて</option>
                    <option value="consulting">コンサルティングについて</option>
                    <option value="recruit">採用について</option>
                    <option value="other">その他</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="name">
                氏名*
              </label>
              <div className="control">
                <input className="input" type="text" name="name" onChange={(e) => handleChange(e)} id="name" required />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="company">
                会社名
              </label>
              <div className="control">
                <input className="input" type="text" name="company" onChange={(e) => handleChange(e)} id="company" />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="email">
                メールアドレス*
              </label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  name="email"
                  onChange={(e) => handleChange(e)}
                  id="email"
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="message">
                お問い合わせ内容*
              </label>
              <div className="control">
                <textarea className="textarea" name="message" onChange={(e) => handleChange(e)} id="message" required />
              </div>
            </div>
            <div className="field">
              <Button type="submit" icon="chevron">
                送信する
              </Button>
            </div>
          </form>
        </ScSection>
      </Inner>
    </Layout>
  )
}

export default ContactPage

const ScPageHeading = styled(PageHeading)`
  margin-bottom: 2.5rem;
  ${mediaQuery('sm')} {
    margin-bottom: 5rem;
  }
`

const ScSection = styled.section`
  max-width: 720px;
`
