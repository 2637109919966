import IconChecked from 'src/images/icons/icon-checked.svg'
import IconChevron from 'src/images/icons/icon-chevron-right.svg'
import { kebab2Pascal } from 'src/utils'

const icons: { [key: string]: string } = {
  IconChecked,
  IconChevron,
}

export const getIcon = (themeName: string): string => {
  const name = `Icon${kebab2Pascal(themeName)}`
  return icons[name]
}
